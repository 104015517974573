/**
 * ValidationPage Selectors
 *
 * The validation state selectors
 */

import { createSelector } from 'reselect';
import { initialState } from './reducer';

const selectValidation = state => state.get('validationQuery', initialState);

const makeSelectLoading = () =>
  createSelector(
    selectValidation,
    validationState => validationState.get('loading'),
  );

const makeSelectValidation = () =>
  createSelector(
    selectValidation,
    validation => validation.get('validationData'),
  );

export { selectValidation, makeSelectValidation, makeSelectLoading };
