/**
 * ActivityReducer
 *
 * The reducer takes handles actions and manipulates the data for activity
 *
 */

import { fromJS } from 'immutable';

import {
  LOAD_LOCATIONS_FILTER_SUCCESS,
  LOAD_LOCATIONS_FILTER,
  LOAD_SUBSIDIARY_FILTER,
  LOAD_SUBSIDIARY_FILTER_SUCCESS,
  LOAD_RESOURCE_FILTER,
  LOAD_RESOURCE_FILTER_SUCCESS,
  LOAD_SOURCE_FILTER,
  LOAD_SOURCE_FILTER_SUCCESS,
} from './constants';

// The initial state of the App
export const initialState = fromJS({
  locationsLoading: false,
  subsidiaryLoading: false,
  resourceLoading: false,
  sourceLoading: false,
  locationsData: {},
  subsidiaryData: [],
  resourceData: [],
  sourceData: [],
  error: false,
});

function activityReducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_LOCATIONS_FILTER:
      return state
        .set('locationsLoading', true)
        .set('error', false)
        .set('locationsData', fromJS({}));
    case LOAD_LOCATIONS_FILTER_SUCCESS:
      return state.set('locationsData', fromJS(action.locations)).set('locationsLoading', false);
    case LOAD_SUBSIDIARY_FILTER:
      return state
        .set('subsidiaryLoading', true)
        .set('error', false)
        .set('subsidiaryData', fromJS([]));
    case LOAD_SUBSIDIARY_FILTER_SUCCESS:
      return state.set('subsidiaryData', fromJS(action.data)).set('subsidiaryLoading', false);
    case LOAD_RESOURCE_FILTER:
      return state
        .set('resourceData', true)
        .set('error', false)
        .set('resourceData', fromJS([]));
    case LOAD_RESOURCE_FILTER_SUCCESS:
      return state.set('resourceData', fromJS(action.data)).set('resourceDataLoading', false);
    case LOAD_SOURCE_FILTER:
      return state
        .set('sourceData', true)
        .set('error', false)
        .set('sourceData', fromJS([]));
    case LOAD_SOURCE_FILTER_SUCCESS:
      return state.set('sourceData', fromJS(action.data)).set('sourceDataLoading', false);
    default:
      return state;
  }
}

export default activityReducer;
