/** App styled components */
import { Layout, Col } from 'antd';
import styled from 'styled-components';

export const AppWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  height: 100%;
`;

export const StyledSider = styled(Layout.Sider)`
  box-shadow: 2px 0 6px rgba(0, 21, 41, 0.05);
  background-color: #fff;
  z-index: 10;
  z-index: 999;

  .ant-layout-sider-trigger {
    color: rgba(0, 0, 0, 0.65);
    background: none;
    border-top: 1px solid #e8e8e8;
  }
`;
//  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
export const StyledLayoutHeader = styled(Layout.Header)`
  padding: 0 12px 0 0;
  background: #fff;
  border-bottom: 1px solid #e8e8e8;
  position: relative;
`;

export const StyledLayout = styled(Layout)`
  height: 100%;
`;

export const StyledLayoutContent = styled(Layout.Content)`
  padding: 24px 24px 0px;
  position: relative;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  @media (max-width: 768px) {
    padding: 6px 6px 0px;
  }
`;

export const StyledColRight = styled(Col)`
  text-align: right;
  @media (max-width: 576px) {
    text-align: left;
  }
`;
