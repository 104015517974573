/**
 *
 * Sidebar
 *
 * This component is the the global side nav. Appears on every page of the site.
 *
 * TODO:
 * - finalize the links
 * - implement the collapse
 */

import React from 'react';
import styled from 'styled-components';
import { NavLink, Link } from 'react-router-dom';
import { Menu, Icon } from 'antd';
import PropTypes from 'prop-types';

const { SubMenu } = Menu;

// STYLES
const SidebarStyled = styled.div`
  ${'' /* width: 256px;
  background-color: rgb(0, 21, 41);
  background-color: white; */};
`;

const StyledMenu = styled(Menu)`
  border-right: 0px;
`;

const Logo = styled.div`
  width: ${props => (props.collapsed ? '40px' : '100%')};
  vertical-align: top;
  height: 42px;
  margin: 11px 0 11px 20px;
  background-image: ${props => props.url && `url(${props.url})`};
  background-size: auto 41px;
  transition: width 0s;
  transition-delay: ${props => (props.collapsed ? '0s' : '300ms')};
`;

/**
 * Get the current active url link from the location prop
 *
 * @param {*} props component props
 */
const getCurrentMenuSelectedKeys = (location) => {
  if (location) {
    const { pathname } = location;
    const keys = pathname.split('/').slice(1);
    if (pathname === '/reports-portal') {
      return ['audit'];
    } else if (pathname === '/reports-portal/inventory') {
      return ['inventory'];
    } else if (keys[1] && keys[1] === 'inventory') {
      return [keys[2]];
    }
    return keys;
  }
  return null;
};
const Sidebar = ({ location, collapsed, entitlements }) => (
  <SidebarStyled>
    <Link to="/">
      <Logo
        collapsed={collapsed}
        url={
          process.env.REACT_APP_BASENAME
            ? `${process.env.REACT_APP_BASENAME}/static/img/logo.png`
            : '/static/img/logo.png'
        }
      />
    </Link>
    {entitlements.Resources &&
    (entitlements.Resources.includes('reports') || entitlements.Resources.includes('*')) ? (
      <StyledMenu
        selectedKeys={getCurrentMenuSelectedKeys(location)}
        mode="inline"
        theme="light"
        defaultOpenKeys={['inventory-menu']}
      >
        <Menu.Item key="audit">
          <NavLink to="/reports-portal/audit">
            <Icon type="sync" />
            <span>Search & Audit</span>
          </NavLink>
        </Menu.Item>
        {entitlements.SourceSystems &&
        (entitlements.SourceSystems.includes('inventory_audit') ||
          entitlements.SourceSystems.includes('*')) ? (
            <SubMenu
              key="inventory-menu"
              title={
                <span>
                  <Icon type="file-text" />
                  <span>Inventory Reports</span>
                </span>
            }
            >
              <Menu.Item key="inventory">
                <NavLink to="/reports-portal/inventory">
                  <Icon type="file-text" />
                  <span>Upload</span>
                </NavLink>
              </Menu.Item>
              <Menu.Item key="closing-months">
                <NavLink to="/reports-portal/inventory/closing-months">
                  <Icon type="file-text" />
                  <span>Closing Months</span>
                </NavLink>
              </Menu.Item>
              <Menu.Item key="ou-configuration">
                <NavLink to="/reports-portal/inventory/ou-configuration">
                  <Icon type="file-text" />
                  <span>OU Configuration</span>
                </NavLink>
              </Menu.Item>
              <Menu.Item key="report-for-completeness">
                <NavLink to="/reports-portal/report-for-completeness">
                  <Icon type="file-text" />
                  <span>Report for Completeness</span>
                </NavLink>
              </Menu.Item>
            </SubMenu>
        ) : null}
        {entitlements.SourceSystems &&
        (entitlements.SourceSystems.includes('order_to_cash') ||
          entitlements.SourceSystems.includes('*')) ? (
            <Menu.Item key="order-to-cash">
              <NavLink to="/reports-portal/order-to-cash">
                <Icon type="share-alt" />
                <span>Order to Cash</span>
              </NavLink>
            </Menu.Item>
        ) : null}
        {entitlements.SourceSystems &&
        (entitlements.SourceSystems.includes('general_reporting') ||
          entitlements.SourceSystems.includes('*')) ? (
            <Menu.Item key="reporting">
              <NavLink to="/reports-portal/reporting">
                <Icon type="file-text" />
                <span>Reporting</span>
              </NavLink>
            </Menu.Item>
        ) : null}
        {entitlements.SourceSystems &&
        (entitlements.SourceSystems.includes('revenue_integration') ||
          entitlements.SourceSystems.includes('*')) ? (
            <Menu.Item key="revenue-integration">
              <NavLink to="/reports-portal/revenue-integration">
                <Icon type="fork" />
                <span>Revenue Integration</span>
              </NavLink>
            </Menu.Item>
        ) : null}
        {entitlements.SourceSystems &&
        (entitlements.SourceSystems.includes('blackline_journal') ||
          entitlements.SourceSystems.includes('*')) ? (
            <Menu.Item key="blackline">
              <NavLink to="/reports-portal/blackline">
                <Icon type="book" />
                <span>Blackline Journal Entry</span>
              </NavLink>
            </Menu.Item>
        ) : null}
      </StyledMenu>
    ) : null}
  </SidebarStyled>
);

Sidebar.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }),
  collapsed: PropTypes.bool,
  entitlements: PropTypes.object, // eslint-disable-line
};

Sidebar.defaultProps = {
  location: {
    pathname: '',
  },
  collapsed: false,
  entitlements: {},
};

export default Sidebar;
