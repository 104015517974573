/*
 * ReportsPortalAudit Actions
 *
 */

import { LOAD_REPORTS, LOAD_REPORTS_SUCCESS, LOAD_REPORTS_ERROR } from './constants';

/**
 * Load the reports, this action starts the request saga
 *
 * @return {object} An action object with a type of LOAD_REPORTS passing the location id
 */
export function loadReports(id, sub, status, startDate, endDate, report, ouid, journalId, token) {
  return {
    type: LOAD_REPORTS,
    id,
    sub,
    status,
    startDate,
    endDate,
    report,
    ouid,
    journalId,
    token,
  };
}

/**
 * Dispatched when the reports is loaded by the request saga
 *
 * @param  {object} data The reports data
 *
 * @return {object}      An action object with a type of LOAD_REPORTS_SUCCESS passing the
 * location data
 */
export function reportsLoaded(data) {
  return {
    type: LOAD_REPORTS_SUCCESS,
    data,
  };
}

/**
 * Dispatched when loading the reports fails
 *
 * @param  {object} error The error
 *
 * @return {object}       An action object with a type of LOAD_REPORTS_ERROR passing the error
 */
export function reportsLoadedError(error) {
  return {
    type: LOAD_REPORTS_ERROR,
    error,
  };
}
