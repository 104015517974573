export const createFilterString = (key, vals) => {
  if (vals.length > 1) {
    const filter = { $or: [] };
    vals.map((val) => {
      const condition = {};
      condition[key] = val;
      filter.$or.push(condition);
      return null;
    });
    return filter;
  }
  const condition = {};
  condition[key] = vals[0]; // eslint-disable-line
  return condition;
};

// ex {"resources":{"$elemMatch":{"name":"gamingplayer"}}}
// ex {"resources":{"$elemMatch":{"source_systems":{"$elemMatch":{"name":"darksky"}}}}}
export const createListFilterString = (listKey, key, vals) => {
  if (vals.length > 1) {
    const filter = { $or: [] };
    vals.map((val) => {
      const condition = { $elemMatch: {} };
      condition.$elemMatch[key] = val;
      const retCondition = {};
      retCondition[listKey] = condition;
      filter.$or.push(retCondition);
      return null;
    });
    return filter;
  }

  const condition = { $elemMatch: {} };
  condition.$elemMatch[key] = vals[0]; // eslint-disable-line
  const retCondition = {};
  retCondition[listKey] = condition;
  return retCondition;
};

export const createInFilterString = vals => ({ $in: [...new Set(vals)] });
