/**
 * ReportsPortalAuditPage Reducer
 *
 * The reducer takes handles actions and manipulates ReportsPortalAuditPage data. This is the only
 * place the to manipulate global data.
 *
 */

import { fromJS } from 'immutable';

import { LOAD_REPORTS_SUCCESS, LOAD_REPORTS, LOAD_REPORTS_ERROR } from './constants';

// The initial state
export const initialState = fromJS({
  loading: false,
  error: false,
  reportsData: {},
});

function locationReducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_REPORTS:
      return state
        .set('loading', true)
        .set('error', false)
        .set('reportsData', fromJS({}));
    case LOAD_REPORTS_SUCCESS:
      return state.set('reportsData', fromJS(action.data)).set('loading', false);
    case LOAD_REPORTS_ERROR:
      return state.set('error', action.error).set('loading', false);
    default:
      return state;
  }
}

export default locationReducer;
