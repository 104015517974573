const requestMiddleware = store => next => (action) => {
  if (action.type.includes('LOAD_')) {
    const state = store.getState();
    const session = state.get('session');
    if (session && session.get('authenticated') && session.get('user').size) {
      const user = session.get('user');
      const issuedDate = new Date(parseInt(user.get('issued_at'), 10));
      const expiresIn = user.get('expires_in');
      const sessionDuration = (new Date().getTime() - issuedDate.getTime()) / 1000;
      const remainingSessionDuration = expiresIn - sessionDuration;
      // check if session is about to expire
      if (remainingSessionDuration < 60 && !state.get('global').get('sessionTimeout')) {
        store.dispatch({ type: 'ede/App/SESSION_TIME_OUT' });
      }
    }
  }
  next(action);
};

export default requestMiddleware;
