/*
 * ValidationPage
 *
 * Displays system validation
 */

import React from 'react';
import { Helmet } from 'react-helmet';
import { Card, Badge } from 'antd';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { fromJS } from 'immutable';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { parse, stringify } from 'qs';

// INTERNALS
import PageHeader from './../../../shared/components/PageHeader/index';
import PageFilter from './../../../shared/components/PageFilter/index';
import injectSaga from './../../../utils/injectSaga';
import injectReducer from './../../../utils/injectReducer';

// REDUCER
import reducer from './reducer';

// SAGAS
import saga from './sagas';

// SELECTORS
import { makeSelectValidation, makeSelectLoading } from './selectors';
import {
  makeSelectSessionUser,
  makeSelectSessionAuthenticated,
  makeSelectSessionChecked,
  makeSelectSessionUserEntitlements,
  makeSelectLocations,
  makeSelectMeta,
} from '../../../App/selectors';

// ACTIONS
import { loadValidation } from './actions';

// STYLES
import { Section, StyledTable, StyledContent } from './../../../global-styles';

export class ValidationPage extends React.Component {
  constructor(props) {
    super(props);
    const { location } = this.props;
    const query = parse(location.search.substr(1));
    this.state = {
      query,
      date:
        query.date ||
        moment()
          .subtract(1, 'day')
          .format('YYYY-MM-DD'),
    };

    // events for back button
    window.onpopstate = () => {
      const backLocation = window.location;
      const backQuery = parse(backLocation.search.substr(1));
      const backDate =
        backQuery.date ||
        moment()
          .subtract(1, 'day')
          .format('YYYY-MM-DD');
      this.filterValidation(backQuery.filters || {}, backDate, true);
      this.setState({
        query: backQuery,
        date: backDate,
      });
    };
  }

  componentDidMount() {
    const {
      checked, authenticated, session, entitlements,
    } = this.props;
    const { query, date } = this.state;
    if (checked && !authenticated) {
      const defaultDate = moment()
        .subtract(1, 'day')
        .format('YYYY-MM-DD');
      this.props.onLoadValidation(query.date || defaultDate);
    } else if (checked && authenticated) {
      const accessToken = session.get('access_token');
      this.props.onLoadValidation(
        query.date || date,
        query.filters || {},
        accessToken,
        entitlements,
      );
    }
  }

  componentDidUpdate(prevProps) {
    const {
      checked, authenticated, session, entitlements,
    } = this.props;
    const { query, date } = this.state;
    if (!prevProps.checked && checked && !authenticated) {
      const defaultDate = moment()
        .subtract(1, 'day')
        .format('YYYY-MM-DD');
      this.props.onLoadValidation(query.date || defaultDate);
    } else if (authenticated && !prevProps.session.size && session.size) {
      const accessToken = session.get('access_token');
      this.props.onLoadValidation(
        query.date || date,
        query.filters || {},
        accessToken,
        entitlements,
      );
    }
  }

  /** Filter data by date */
  onDateFilter = (date) => {
    const { session, history, entitlements } = this.props;
    const { query } = this.state;
    const accessToken = session.get('access_token');
    const dateFormat = date.format('YYYY-MM-DD');
    this.setState({ date: dateFormat });
    this.props.onLoadValidation(dateFormat, query.filters || {}, accessToken, entitlements);
    history.push({
      search: stringify({ date: dateFormat, filters: query.filters }),
    });
  };

  /** Filter validation with selected filters */
  filterValidation = (filters, paramDate, preventHistoryChange) => {
    const {
      history, onLoadValidation, session, entitlements,
    } = this.props;
    const accessToken = session.get('access_token');
    onLoadValidation(paramDate || this.state.date, filters, accessToken, entitlements);
    const newQuery = {
      date: paramDate || this.state.date,
      filters,
    };
    // when the back button is pressed we do not want to push new query parameters
    // this is ugly, but works
    if (!preventHistoryChange) {
      history.push({
        search: stringify(newQuery),
      });
    }
    this.setState({ query: newQuery });
  };

  /** Render status badge bases on status */
  renderBadge = (val) => {
    if (val.passed) {
      return (
        <div key={val._id}>
          <Link to={`/${val._links.self.href}`}>
            <Badge status="success" text={val.source_system} />
          </Link>
        </div>
      );
    } else if (val.needs_validation) {
      return (
        <div key={val._id}>
          <Link to={`/${val._links.self.href}`}>
            <Badge status="default" text={val.source_system} />
          </Link>
        </div>
      );
    }
    return (
      <div key={val._id}>
        <Link to={`/${val._links.self.href}`}>
          <Badge status="error" text={val.source_system} />
        </Link>
      </div>
    );
  };

  /** Render validation counts */
  renderOverview = (rec) => {
    let passingCnt = 0;
    let failingCnt = 0;
    let defaultCnt = 0;
    rec.validation.map((val) => {
      if (val.passed) {
        passingCnt += 1;
      } else if (val.needs_validation) {
        defaultCnt += 1;
      } else {
        failingCnt += 1;
      }
      return null;
    });
    return (
      <span>
        <Badge
          count={passingCnt}
          style={{ backgroundColor: '#52c41a', fontFamily: 'sans-serif' }}
          showZero
        />
        <Badge count={failingCnt} style={{ fontFamily: 'sans-serif' }} showZero />
        <Badge
          count={defaultCnt}
          style={{ backgroundColor: '#999', color: '#fff', boxShadow: '0 0 0 1px #fff inset' }}
          showZero
        />
      </span>
    );
  };

  /** Render component */
  render() {
    const columns = [
      {
        title: 'Status',
        key: 'status',
        render: (record) => {
          if (record.needs_validation) {
            return <Badge status="default" text="Needs Validation" />;
          } else if (record.passed) {
            return <Badge status="success" text="Passed" />;
          }
          return <Badge status="error" text="Failed" />;
        },
        sorter: (a, b) => {
          // convert validation status to a number for sorting
          let aVal = a.needs_validation ? 2 : 0;
          let bVal = b.needs_validation ? 2 : 0;
          aVal = a.passed ? 1 : aVal;
          bVal = b.passed ? 1 : bVal;
          if (aVal < bVal) {
            return -1;
          }
          if (aVal > bVal) {
            return 1;
          }
          return 0;
        },
      },
      {
        title: 'Date',
        key: 'date',
        dataIndex: 'date',
        sorter: (a, b) => {
          if (a.date < b.date) {
            return -1;
          }
          if (a.date > b.date) {
            return 1;
          }
          return 0;
        },
      },
      {
        title: 'Location',
        key: 'location',
        dataIndex: 'location._id',
        sorter: (a, b) => {
          if (a.location._id < b.location._id) {
            return -1;
          }
          if (a.location._id > b.location._id) {
            return 1;
          }
          return 0;
        },
      },
      {
        title: 'Resource',
        key: 'resource',
        dataIndex: 'resource',
        sorter: (a, b) => {
          if (a.resource < b.resource) {
            return -1;
          }
          if (a.resource > b.resource) {
            return 1;
          }
          return 0;
        },
      },
      {
        title: 'Source System',
        key: 'source_system',
        dataIndex: 'source_system',
        sorter: (a, b) => {
          if (a.source_system < b.source_system) {
            return -1;
          }
          if (a.source_system > b.source_system) {
            return 1;
          }
          return 0;
        },
      },
      {
        title: 'Storage System',
        key: 'system',
        render: record => (
          <div>
            {record.validation_storage_system1} &rarr; {record.validation_storage_system2}
          </div>
        ),
      },
      {
        key: 'link',
        dataIndex: '_id',
        render: id => <Link to={`/explorer/validation/${id}`}>Details</Link>,
      },
    ];
    const breadcrumbs = [
      {
        key: 1,
        path: '/explorer',
        title: 'Dashboard',
      },
      {
        key: 2,
        path: '/explorer/validation',
        title: 'Validation',
      },
    ];

    const {
      loading, validation, locations, meta,
    } = this.props;

    const { query, date } = this.state;

    // const subsidiaries = meta.get('subsidiaries') ? meta.get('subsidiaries').toJS() : [];
    const resources = meta.get('resources') ? meta.get('resources').toJS() : [];
    const sourceSystems = meta.get('sourceSystems') ? meta.get('sourceSystems').toJS() : [];
    const validationStatuses = [
      { id: 'passed', name: 'Passed' },
      { id: 'failed', name: 'Failed' },
      { id: 'needs_validation', name: 'Needs Validation' },
    ];

    const filters = query.filters || {};

    return (
      <StyledContent>
        <Helmet>
          <title>Validation</title>
          <meta name="description" content="Enterprise Data Explorer Dashboard" />
        </Helmet>
        <PageHeader
          breadcrumbs={breadcrumbs}
          dateFilterAction={this.onDateFilter}
          dateFilter
          day
          dateValue={moment(date)}
        >
          <h1>Validation</h1>
        </PageHeader>
        <Section>
          <PageFilter
            onSubmit={this.filterValidation}
            locations={locations.get('_items') && locations.get('_items').toJS()}
            defaultLocations={filters.locations}
            resources={resources}
            defaultResources={filters.resources}
            sourceSystems={sourceSystems}
            defaultSourceSystems={filters.sourceSystems}
            status={validationStatuses}
            defaultStatus={filters.status}
          />
        </Section>
        <Section>
          <Card>
            <StyledTable
              loading={!!loading}
              rowKey="_id"
              dataSource={validation.get('_items') ? validation.get('_items').toJS() : null}
              columns={columns}
              size="small"
              pagination={{ defaultPageSize: 20 }}
            />
          </Card>
        </Section>
      </StyledContent>
    );
  }
}

ValidationPage.propTypes = {
  onLoadValidation: PropTypes.func,
  loading: PropTypes.bool,
  validation: ImmutablePropTypes.map, // eslint-disable-line
  session: ImmutablePropTypes.map, // eslint-disable-line
  validation: ImmutablePropTypes.map, // eslint-disable-line
  history: PropTypes.object.isRequired, // eslint-disable-line
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }),
  checked: PropTypes.bool,
  authenticated: PropTypes.bool,
  entitlements: PropTypes.object, // eslint-disable-line
  meta: ImmutablePropTypes.map, // eslint-disable-line
  locations: ImmutablePropTypes.map, // eslint-disable-line
};

ValidationPage.defaultProps = {
  onLoadValidation: () => {
    console.warn('onLoadValidation not defined in Validation Component'); // eslint-disable-line
  },
  loading: false,
  validation: fromJS({}),
  location: {
    pathname: '',
  },
  locations: fromJS({}),
  checked: false,
  authenticated: false,
};

export function mapDispatchToProps(dispatch) {
  return {
    onLoadValidation: (date, filters = {}, token, entitlements) => {
      dispatch(loadValidation(date, filters, token, entitlements));
    },
  };
}

const mapStateToProps = createStructuredSelector({
  loading: makeSelectLoading(),
  validation: makeSelectValidation(),
  session: makeSelectSessionUser(),
  authenticated: makeSelectSessionAuthenticated(),
  checked: makeSelectSessionChecked(),
  entitlements: makeSelectSessionUserEntitlements(),
  locations: makeSelectLocations(),
  meta: makeSelectMeta(),
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);
const withReducer = injectReducer({ key: 'validationQuery', reducer });
const withSaga = injectSaga({ key: 'validationQuery', saga });

export default compose(
  withReducer,
  withSaga,
  withConnect,
)(ValidationPage);
