/**
 * AppReducer
 *
 * The reducer takes handles actions and manipulates the app data.
 */

import { fromJS } from 'immutable';

import {
  LOAD_LOCATIONS,
  LOAD_LOCATIONS_SUCCESS,
  LOAD_LOCATIONS_ERROR,
  LOAD_AUTH,
  LOAD_AUTH_SUCCESS,
  LOAD_AUTH_ERROR,
  LOAD_AUTH_AD,
  LOAD_VALIDATION,
  LOAD_VALIDATION_SUCCESS,
  LOAD_VALIDATION_ERROR,
  LOAD_META,
  LOAD_META_SUCCESS,
  LOAD_META_ERROR,
  CLEAR_AUTH_ERROR,
  SESSION_TIME_OUT,
  RESET_SESSION_TIME_OUT,
} from './constants';

// The initial state of the App
const initialState = fromJS({
  locationsLoading: false,
  locationsError: false,
  locationsData: {},
  authLoading: false,
  authError: false,
  validationLoading: false,
  validationError: false,
  validationData: {},
  metaLoading: false,
  metaError: false,
  metaData: {},
  sessionTimeout: false,
});

function appReducer(state = initialState, action) {
  switch (action.type) {
    // locations
    case LOAD_LOCATIONS:
      return state
        .set('locationsLoading', true)
        .set('locationsError', false)
        .set('locationsData', fromJS({}));
    case LOAD_LOCATIONS_SUCCESS:
      return state.set('locationsData', fromJS(action.locations)).set('locationsLoading', false);
    case LOAD_LOCATIONS_ERROR:
      return state.set('locationsError', action.error).set('locationsLoading', false);
    case LOAD_AUTH:
      return state.set('authLoading', true);
    case LOAD_AUTH_SUCCESS:
      return state.set('authLoading', false);
    case LOAD_AUTH_ERROR:
      return state.set('authLoading', false).set('authError', fromJS(action.error));
    case CLEAR_AUTH_ERROR:
      return state.set('authLoading', false).set('authError', false);
    // ad auth
    case LOAD_AUTH_AD:
      return state.set('authLoading', true);
    // validation
    case LOAD_VALIDATION:
      return state
        .set('validationLoading', true)
        .set('validationError', false)
        .set('validationData', fromJS({}));
    case LOAD_VALIDATION_SUCCESS:
      return state.set('validationData', fromJS(action.data)).set('validationLoading', false);
    case LOAD_VALIDATION_ERROR:
      return state.set('validationError', action.error).set('validationLoading', false);
    // validation
    case LOAD_META:
      return state
        .set('metaLoading', true)
        .set('metaError', false)
        .set('metaData', fromJS({}));
    case LOAD_META_SUCCESS:
      return state.set('metaData', fromJS(action.data)).set('metaLoading', false);
    case LOAD_META_ERROR:
      return state.set('metaError', action.error).set('metaLoading', false);
    case SESSION_TIME_OUT:
      return state.set('sessionTimeout', true);
    case RESET_SESSION_TIME_OUT:
      return state.set('sessionTimeout', false);
    default:
      return state;
  }
}

export default appReducer;
