/**
 * AppRouter
 *
 * Route to app pages
 *
 * TODO: Secure the routes
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';

import DashboardPage from './../ede/pages/DashboardPage/Loadable';
import LocationDashboardPage from './../ede/pages/LocationDashboardPage/Loadable';
import LocationDetailPage from './../ede/pages/LocationDetailPage/Loadable';
import SystemDashboardPage from './../ede/pages/SystemDashboardPage/Loadable';
import SystemDetailPage from './../ede/pages/SystemDetailPage/Loadable';
import CatalogPage from './../ede/pages/CatalogPage/Loadable';
import ActivityPage from './../ede/pages/ActivityPage/Loadable';
import ValidationPage from './../ede/pages/ValidationPage/Loadable';
import ValidationQueryPage from './../ede/pages/ValidationQueryPage';
import ValidationDetailPage from './../ede/pages/ValidationDetailPage/Loadable';
import ValidationFailuresPage from './../ede/pages/ValidationFailuresPage/Loadable';
import TransactionsReportDailySummaryPage
  from './../ede/pages/TransactionsReportDailySummaryPage/Loadable';
import TransactionsReportDailyLocationSummaryPage
  from './../ede/pages/TransactionsReportDailyLocationSummaryPage/Loadable';
import TransactionsReportDailyLocationTendersPage
  from './../ede/pages/TransactionsReportDailyLocationTendersPage/Loadable';
import NotFound from './../shared/pages/NotFoundPage/NotFoundPage';

/** AppRouter component */
const EDERouter = ({ checked, scrollContainer, accessToken }) =>
  (checked ? (
    <Switch>
      <Route
        exact
        path="/explorer"
        key="route-home"
        render={({ ...props }) => <DashboardPage {...props} accessToken={accessToken} />}
      />
      <Route
        exact
        path="/explorer/locations"
        key="route-location-dash"
        render={({ ...props }) => <LocationDashboardPage {...props} accessToken={accessToken} />}
      />
      <Route
        exact
        path="/explorer/locations/:name"
        key="route-location-detail"
        render={({ ...props }) => <LocationDetailPage {...props} accessToken={accessToken} />}
      />
      <Route
        exact
        path="/explorer/systems"
        key="route-system-dash"
        render={({ ...props }) => <SystemDashboardPage {...props} accessToken={accessToken} />}
      />
      <Route
        exact
        path="/explorer/systems/:name"
        key="route-system-detail"
        render={({ ...props }) => <SystemDetailPage {...props} accessToken={accessToken} />}
      />
      <Route
        exact
        path="/explorer/catalog"
        render={({ ...props }) => (
          <CatalogPage {...props} scrollContainer={scrollContainer} accessToken={accessToken} />
        )}
        key="route-catalog"
      />
      <Route
        exact
        path="/explorer/activity"
        key="route-activity"
        render={({ ...props }) => <ActivityPage {...props} accessToken={accessToken} />}
      />
      <Route
        exact
        path="/explorer/location-validation"
        key="route-location-validation"
        render={({ ...props }) => <ValidationPage {...props} accessToken={accessToken} />}
      />
      <Route
        exact
        path="/explorer/validation"
        key="route-validation"
        render={({ ...props }) => <ValidationQueryPage {...props} accessToken={accessToken} />}
      />
      <Route
        exact
        path="/explorer/validation-failures"
        key="route-validation-failures"
        render={({ ...props }) => <ValidationFailuresPage {...props} accessToken={accessToken} />}
      />
      <Route
        exact
        path="/explorer/validation/:id"
        key="route-validation-detail"
        render={({ ...props }) => <ValidationDetailPage {...props} accessToken={accessToken} />}
      />
      <Route
        exact
        path="/explorer/transactions-report/daily-summary"
        key="route-transactions-report-daily-summary"
        render={({ ...props }) => <TransactionsReportDailySummaryPage {...props} accessToken={accessToken} />}
      />
      <Route
        exact
        path="/explorer/transactions-report/daily-location-summary"
        key="route-transactions-report-daily-location-summary"
        render={({ ...props }) => <TransactionsReportDailyLocationSummaryPage {...props} accessToken={accessToken} />}
      />
      <Route
        exact
        path="/explorer/transactions-report/daily-location-tenders"
        key="route-transactions-report-daily-location-tenders"
        render={({ ...props }) => <TransactionsReportDailyLocationTendersPage {...props} accessToken={accessToken} />}
      />
      <Route component={NotFound} name="not-found" />
    </Switch>
  ) : null);

EDERouter.propTypes = {
  checked: PropTypes.bool.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  scrollContainer: PropTypes.object, // eslint-disable-line
  accessToken: PropTypes.string, // eslint-disable-line
};

EDERouter.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default EDERouter;
