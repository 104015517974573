/* eslint jsx-a11y/anchor-is-valid: 0 */
import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { Alert, Badge, Card, Form, DatePicker } from 'antd';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { fromJS } from 'immutable';
import moment from 'moment';
import ImmutablePropTypes from 'react-immutable-proptypes';

// INTERNAL
import PageHeader from './../../../shared/components/PageHeader/index';

// SELECTORS
import {
  makeSelectLocations,
  makeSelectLocationsLoading,
  makeSelectValidation,
  makeSelectSessionChecked,
  makeSelectSessionAuthenticated,
  makeSelectSessionUserEntitlements,
  makeSelectSessionUser,
  makeSelectMeta,
} from './../../../App/selectors';

// STYLES
import { Section, StyledContent, StyledTable } from './../../../global-styles';

const FormItem = Form.Item;

const ouColumns = [
  {
    title: 'OU Name',
    key: 'name',
    dataIndex: 'name',
    onFilter: (value, record) => record.name.indexOf(value) === 0,
    sorter: (a, b) => a.name.length - b.name.length,
    sortDirections: ['descend'],
  },
  {
    title: 'ID',
    key: 'ou',
    dataIndex: 'ou',
    sorter: (a, b) => a.ou - b.ou,
  },
  {
    title: 'Status',
    key: 'status',
    render: () => <Badge status="error" text="Incomplete" />,
  },
];

class ReportsPortalReportPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      reportingResults: null,
    };
  }

  componentDidMount() {
    // Display the last month by default
    this.handleMonthPickerChange(moment().subtract(1, 'month'));
  }

  componentDidUpdate() {}

  getCompletenessReport = (date) => {
    const { session, locations } = this.props;
    const reportEntries = [];
    const host = process.env.REACT_APP_EDC_HOST || '';
    const queryString = `year=${date.year()}&month=${date.format('M')}`;

    return fetch(`${host}/dnc/edc/v1/inventorydepot/report/completion?${queryString}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${session.get('access_token')}`,
        Accept: 'application/json',
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Report error getting data');
      })
      .then((response) => {
        response.incomplete.map((report) => {
          reportEntries.push({
            id: report.key,
            is_closed: report.is_closed,
            loc: report.location._id,
            ou: report.location.operating_unit._id,
            name: locations
              .get('_items')
              .find(obj => obj.get('_id') === report.location._id)
              .get('operating_units')
              .find(obj => obj.get('id') === report.location.operating_unit._id)
              .get('name'),
          });
          return null;
        });
        this.setState({ reportingResults: fromJS(reportEntries) });
      });
  };

  handleMonthPickerChange = (date) => {
    if (date instanceof moment) {
      this.getCompletenessReport(date);
    }
  };

  render() {
    const {
      checked, authenticated, form, meta, locationsLoading,
    } = this.props;

    const formItemLayout = {
      labelCol: { span: 6 },
      wrapperCol: { span: 14 },
    };

    return (
      <StyledContent>
        <Helmet>
          <title>Reports Portal</title>
          <meta name="description" content="Enterprise Data Explorer Reports Portal" />
        </Helmet>
        <PageHeader title="Reports">
          <h1>Report for Completeness</h1>
        </PageHeader>
        <Section>
          <Card loading={!!locationsLoading}>
            {checked && !authenticated ? (
              <Alert
                message="You must login to access the reports portal."
                description=""
                type="error"
              />
            ) : (
              <div>
                <FormItem {...formItemLayout} label="Choose Month" key="month">
                  {form.getFieldDecorator('month', {
                    rules: [
                      {
                        type: 'object',
                        required: true,
                        message: 'Please select a month!',
                      },
                    ],
                    initialValue: moment().subtract(1, 'month'),
                  })(
                    <DatePicker.MonthPicker
                      onChange={date => this.handleMonthPickerChange(date)}
                    />,
                  )}
                </FormItem>
                <StyledTable
                  rowKey="id"
                  pagination={{ defaultPageSize: 20 }}
                  dataSource={
                    this.state.reportingResults ? this.state.reportingResults.toJS() : null
                  }
                  columns={ouColumns}
                  size="middle"
                  loading={!!locationsLoading || !this.state.reportingResults}
                />
              </div>
            )}
          </Card>
        </Section>
      </StyledContent>
    );
  }
}

ReportsPortalReportPage.propTypes = {
  session: ImmutablePropTypes.map, // eslint-disable-line
  locations: ImmutablePropTypes.map, // eslint-disable-line
  locationsLoading: PropTypes.bool,
  checked: PropTypes.bool,
  authenticated: PropTypes.bool,
  entitlements: PropTypes.object, // eslint-disable-line
  form: PropTypes.object, // eslint-disable-line
  meta: ImmutablePropTypes.map, // eslint-disable-line
  match: PropTypes.object, // eslint-disable-line
  reportingResults: PropTypes.object, // eslint-disable-line
};

ReportsPortalReportPage.defaultProps = {
  onLoadReports: () => console.warn('onLoadReports is not defined'), // eslint-disable-line
  checked: false,
  authenticated: false,
  locations: fromJS({}),
  form: {},
  reportingResults: fromJS([]),
  locationsLoading: false,
};

const mapStateToProps = createStructuredSelector({
  locations: makeSelectLocations(),
  locationsLoading: makeSelectLocationsLoading(),
  validation: makeSelectValidation(),
  checked: makeSelectSessionChecked(),
  session: makeSelectSessionUser(),
  authenticated: makeSelectSessionAuthenticated(),
  entitlements: makeSelectSessionUserEntitlements(),
  meta: makeSelectMeta(),
});

const WrappedReportsPortalReportPage = Form.create()(ReportsPortalReportPage);

const withConnect = connect(mapStateToProps);

export default compose(withConnect)(WrappedReportsPortalReportPage);
