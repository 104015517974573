/**
 * PageHeader
 *
 * Information Displayed:
 * - page title
 * - breadcrums
 * - validation
 * - children - jsx can be passed as children and rendered by the header
 */

import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { Icon } from 'antd';

// INTERNAL
import DateFilter from './../DateFilter';

// STYLES
const StyledBreadCrumbs = styled.div`
  ul {
    list-style: none;
    padding-left: 0;
    li {
      display: inline-block;
    }
    .separator {
      margin-right: 12px;
    }
  }
`;

const StyledPageHeader = styled.div`
  margin: -24px -24px 24px;
  background-color: white;
  display: flex;
  justify-content: space-between;

  @media (max-width: 768px) {
    margin: -6px -6px 6px;
  }
`;

const StyledPageHeaderContent = styled.div`
  margin: 0px 24px 0px;
  padding: 24px 0;
`;

const StyledNavLink = styled(NavLink)`
  margin-right: 12px;
  &.active {
    color: rgba(0, 0, 0, 0.45);
  }
`;

const StyledDateFilter = styled.div`
  display: flex;
  float: right;
  align-self: flex-end;
  margin: 0 24px 24px 0;
`;

const StyledValidation = styled.div`
  display: flex;
  float: right;
  align-self: flex-end;
  margin: 0 24px 36px 0;
  font-size: 72px;
`;

/**
 * Render links to breadcrumb list
 * @param {*} breadcrums list of crumbs
 */
const renderBreadcrumbs = breadcrums => (
  <StyledBreadCrumbs>
    <ul>
      {breadcrums.map((crumb, index) => (
        <li key={crumb.key}>
          <StyledNavLink exact to={crumb.path} activeClassName="active">
            {crumb.title}
          </StyledNavLink>
          {index < breadcrums.length - 1 ? <span className="separator">/</span> : null}
        </li>
      ))}
    </ul>
  </StyledBreadCrumbs>
);

const renderValidation = (validation) => {
  if (validation.get('passed')) {
    return (
      <StyledValidation>
        <Icon type="check-circle" style={{ color: '#52c41a' }} />
      </StyledValidation>
    );
  } else if (validation.get('needs_validation')) {
    return (
      <StyledValidation>
        <Icon type="minus-circle" />
      </StyledValidation>
    );
  }
  return (
    <StyledValidation>
      <Icon type="close-circle" style={{ color: '#f5222d' }} />
    </StyledValidation>
  );
};

/** PageHeader component */
const PageHeader = ({
  breadcrumbs,
  dateFilter,
  dateFilterAction,
  range,
  day,
  children,
  validation,
  defaultDate,
  dateValue,
}) => (
  <StyledPageHeader>
    <StyledPageHeaderContent>
      {breadcrumbs ? renderBreadcrumbs(breadcrumbs) : null}
      {children && children}
    </StyledPageHeaderContent>
    {dateFilter && (
      <StyledDateFilter>
        <DateFilter
          dateFilterAction={dateFilterAction}
          range={range}
          day={day}
          defaultDate={defaultDate}
          value={dateValue}
        />
      </StyledDateFilter>
    )}
    {validation && renderValidation(validation)}
  </StyledPageHeader>
);

PageHeader.propTypes = {
  breadcrumbs: PropTypes.arrayOf(PropTypes.object),
  dateFilter: PropTypes.bool,
  dateFilterAction: PropTypes.func,
  range: PropTypes.bool,
  day: PropTypes.bool,
  children: PropTypes.object, // eslint-disable-line
  validation: PropTypes.object, // eslint-disable-line
  defaultDate: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  dateValue: PropTypes.object, // eslint-disable-line
};

PageHeader.defaultProps = {
  breadcrumbs: null,
  dateFilter: false,
  dateFilterAction: () => console.warn('no date filter action'),
  range: false,
  day: false,
  validation: null,
  defaultDate: null,
};

export default PageHeader;
