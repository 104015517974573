import React from 'react';
import { Helmet } from 'react-helmet';
import { Button, Row, Col, Alert } from 'antd';
import styled from 'styled-components';

// STYLES
import { StyledContent } from './../../../global-styles';

const StyledImg = styled.img`
  margin: 0 auto;
  width: 100%;
  max-width: 400px;
  @media (max-width: 991px) {
    width: 200px;
    margin-bottom: 40px;
  }
`;

const StyledHeader = styled.h1`
  color: #434e59;
  font-size: 72px;
  font-weight: 600;
  line-height: 72px;
  margin-bottom: 20px;
`;

const StyledText = styled.div`
  color: rgba(0, 0, 0, 0.45);
  font-size: 20px;
  line-height: 28px;
  margin-bottom: 12px;
`;

const StyledFlex = styled.div`
  align-items: center;
  display: flex;
  height: 80%;
  min-height: 500px;

  @media (max-width: 991px) {
    min-height: auto;
    text-align: center;
  }
`;

const NotFoundPage = () => (
  <StyledContent>
    <Helmet>
      <title>404</title>
      <meta name="description" content="Page Not Found" />
    </Helmet>
    <Row>
      <Alert
        style={{ width: '75%', maxWidth: 750, margin: '0 auto' }}
        message="How Did I Get Here?"
        description="Updates have been applied to the structure of the Data Solutions Platform. Some URLs have changed. Please note URL changes and update your bookmarks and documentation accordingly."
        type="error"
      />
    </Row>
    <Row>
      <Col lg={12} md={24} style={{ textAlign: 'center' }}>
        <StyledFlex>
          <StyledImg src="/static/img/404.svg" alt="404" />
        </StyledFlex>
      </Col>
      <Col lg={12} md={24}>
        <StyledFlex>
          <div style={{ width: '100%' }}>
            <StyledHeader>404</StyledHeader>
            <StyledText>Sorry, the page you visited does not exist</StyledText>
            <Button href="/">Back to home</Button>
          </div>
        </StyledFlex>
      </Col>
    </Row>
  </StyledContent>
);

export default NotFoundPage;
