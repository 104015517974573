/*
 * App Actions
 *
 * Handles global app data and auth
 */

import { sessionService } from 'redux-react-session';

import {
  LOAD_AUTH,
  LOAD_AUTH_SUCCESS,
  LOAD_AUTH_ERROR,
  CLEAR_AUTH_ERROR,
  LOAD_AUTH_AD,
  LOAD_LOCATIONS,
  LOAD_LOCATIONS_ERROR,
  LOAD_LOCATIONS_SUCCESS,
  LOAD_VALIDATION,
  LOAD_VALIDATION_SUCCESS,
  LOAD_VALIDATION_ERROR,
  LOAD_META,
  LOAD_META_SUCCESS,
  LOAD_META_ERROR,
  LOG_OUT,
  SESSION_TIME_OUT,
  RESET_SESSION_TIME_OUT,
} from './constants';

/**
 * Load the auth, this action starts the request saga
 *
 * @return {object} An action object with a type of LOAD_AUTH
 */
export function loadAuth(client, history, redirect) {
  return {
    type: LOAD_AUTH,
    client,
    history,
    redirect,
  };
}

/**
 * Dispatched when the auth is loaded by the request saga
 *
 * @param  {object} data The auth data
 *
 * @return {object}      An action object with a type of LOAD_AUTH_SUCCESS passing the repos
 */
export function authLoaded(data, history, redirect) {
  const token = data.access_token;
  // set login preference in local storage
  localStorage.setItem('loginPreference', data.OktaUserProfile ? 'AD' : 'API');
  return {
    type: LOAD_AUTH_SUCCESS,
    token,
    data,
    history,
    redirect,
  };
}

/**
 * Logout of user
 *
 * @return {object} An action object with a type of LOAD_AUTH
 */
export function authLogout(history) {
  try {
    sessionService.deleteSession();
    sessionService.deleteUser();
  } catch (e) {
    console.warn('error removing session - ignore if running tests'); // eslint-disable-line
  }
  history.push('/login');
  return {
    type: LOG_OUT,
  };
}

/**
 * Dispatched when loading the auth fails
 *
 * @param  {object} error The error
 *
 * @return {object}       An action object with a type of LOAD_AUTH_ERROR passing the error
 */
export function authLoadingError(error) {
  return {
    type: LOAD_AUTH_ERROR,
    error,
  };
}

// AD authentication

/**
 * Load the auth via AD, this action starts the request saga
 *
 * @return {object} An action object with a type of LOAD_AUTH
 */
export function loadAuthAD(client, history, redirect) {
  return {
    type: LOAD_AUTH_AD,
    client,
    history,
    redirect,
  };
}

/**
 * Load the locations, this action starts the request saga
 *
 * @return {object} An action object with a type of LOAD_LOCATIONS
 */
export function loadLocations(token, entitlements) {
  return {
    type: LOAD_LOCATIONS,
    token,
    entitlements,
  };
}

/**
 * Dispatched when the locations are loaded by the request saga
 *
 * @param  {object} locations The locaitons data
 *
 * @return {object}      An action object with a type of LOAD_LOACTIONS_SUCCESS passing the repos
 */
export function locationsLoaded(locations) {
  return {
    type: LOAD_LOCATIONS_SUCCESS,
    locations,
  };
}

/**
 * Dispatched when loading the locations fails
 *
 * @param  {object} error The error
 *
 * @return {object}       An action object with a type of LOAD_LOCATIONS_ERROR passing the error
 */
export function locationsLoadingError(error) {
  return {
    type: LOAD_LOCATIONS_ERROR,
    error,
  };
}

/**
 * Load the validation, this action starts the request saga
 *
 * @param  {string} token The auth token
 *
 * @return {object} An action object with a type of LOAD_VALIDATION passing token and date
 */
export function loadValidation(token, entitlements) {
  return {
    type: LOAD_VALIDATION,
    token,
    entitlements,
  };
}

/**
 * Dispatched when the validation is loaded by the request saga
 *
 * @param  {object} validation The validation data
 * @param  {object} locations The locations data
 *
 * @return {object}  An action object with a type of LOAD_VALIDATION_SUCCESS passing the data
 */
export function validationLoaded(validation, locations) {
  const validationData = {};
  locations._items.map((loc) => {
    const validationFiltered = validation._items.filter(val => val.location._id === loc._id);
    const newLoc = loc;
    newLoc.validation = validationFiltered;
    validationData[newLoc.name] = validationFiltered;
    return newLoc;
  });
  const data = {
    ...validationData,
  };
  return {
    type: LOAD_VALIDATION_SUCCESS,
    data,
  };
}

/**
 * Dispatched when loading the validation fails
 *
 * @param  {object} error The error
 *
 * @return {object}       An action object with a type of LOAD_VALIDATION_ERROR passing the error
 */
export function validationLoadingError(error) {
  return {
    type: LOAD_VALIDATION_ERROR,
    error,
  };
}

/**
 * Load the meta, this action starts the request saga
 *
 * @param  {string} token The auth token
 *
 * @return {object} An action object with a type of LOAD_META passing token and date
 */
export function loadMeta(token, entitlements) {
  return {
    type: LOAD_META,
    token,
    entitlements,
  };
}

/**
 * Dispatched when the meta is loaded by the request saga
 *
 * @param  {object} subsidiaries
 * @param  {object} sourceSystems
 * @param  {object} resources
 *
 * @return {object}  An action object with a type of LOAD_META_SUCCESS passing the data
 */
export function metaLoaded(subsidiaries, sourceSystems, resources) {
  const data = {
    subsidiaries,
    sourceSystems,
    resources,
  };
  return {
    type: LOAD_META_SUCCESS,
    data,
  };
}

/**
 * Dispatched when loading the meta fails
 *
 * @param  {object} error The error
 *
 * @return {object}       An action object with a type of LOAD_META_ERROR passing the error
 */
export function metaLoadingError(error) {
  return {
    type: LOAD_META_ERROR,
    error,
  };
}

export function clearAuthError() {
  return {
    type: CLEAR_AUTH_ERROR,
  };
}

export function sessionTimeout() {
  try {
    sessionService.deleteSession();
    sessionService.deleteUser();
  } catch (e) {
    console.warn('error removing session - ignore if running tests'); // eslint-disable-line
  }
  return {
    type: SESSION_TIME_OUT,
  };
}

export function resetSessionTimeout() {
  return {
    type: RESET_SESSION_TIME_OUT,
  };
}
