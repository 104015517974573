/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages.
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { Layout } from 'antd';

// INTERNALS
import Header from '../shared/components/Header/index';
import LandingPage from '../landing/LandingPage/LandingPage';

import { authLogout } from './actions';

// SELECTORS
import {
  makeSelectLocations,
  makeSelectLocationsError,
  makeSelectLocation,
  makeSelectSessionAuthenticated,
  makeSelectSessionChecked,
  makeSelectSessionUserEmail,
  makeSelectSessionUser,
  makeSelectSessionUserEntitlements,
  makeSelectSessionTimeout,
} from './selectors';

// STYLES
import { StyledLayout, StyledLayoutHeader, StyledLayoutContent } from './styles';

/**
 * App container componentent
 */
export class EDEApp extends React.Component {
  constructor(props) {
    super(props);
    this.scrollContainer = null;
  }
  /** Render App */
  render() {
    const { user, onLogout } = this.props;
    return (
      <StyledLayout>
        <Helmet titleTemplate="%s - Delaware North" defaultTitle="Delaware North Data Solutions">
          <meta name="description" content="Delaware North Data Solutions" />
        </Helmet>
        <Layout>
          <StyledLayoutHeader>
            <Header logoutAction={onLogout} user={user} />
          </StyledLayoutHeader>
          <StyledLayoutContent
            ref={(node) => {
              this.scrollContainer = node;
            }}
            id="layout-content"
          >
            <LandingPage />
          </StyledLayoutContent>
        </Layout>
      </StyledLayout>
    );
  }
}

EDEApp.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }),
  user: PropTypes.string,
  session: ImmutablePropTypes.map, // eslint-disable-line
  locationsError: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]), // eslint-disable-line
  locations: ImmutablePropTypes.map, // eslint-disable-line
  entitlements: PropTypes.object, // eslint-disable-line
  history: PropTypes.object.isRequired, // eslint-disable-line
  onLogout: PropTypes.func,
};

EDEApp.defaultProps = {
  location: {
    pathname: '',
  },
  user: null,
  session: null,
  onLogout: () => {
    console.warn('onLogout not defined in landingApp Component'); // eslint-disable-line
  },
};
export function mapDispatchToProps(dispatch) {
  return {
    onLogout: (history) => {
      dispatch(authLogout(history));
    },
  };
}

const mapStateToProps = createStructuredSelector({
  location: makeSelectLocation(),
  locations: makeSelectLocations(),
  locationsError: makeSelectLocationsError(),
  authenticated: makeSelectSessionAuthenticated(),
  checked: makeSelectSessionChecked(),
  user: makeSelectSessionUserEmail(),
  session: makeSelectSessionUser(),
  entitlements: makeSelectSessionUserEntitlements(),
  sessionTimeout: makeSelectSessionTimeout(),
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(withConnect)(EDEApp);
