/**
 * PageFilter
 *
 * Filter inputs for filtering data across the ede
 *
 * Supported filters:
 * - locations
 * - resources
 * - subsidiaries
 * - source systems
 * - storage systems
 */

import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Row, Col, Card, Select, Form, Button } from 'antd';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { fromJS } from 'immutable';

// INTERNALS
import injectSaga from './../../../utils/injectSaga';
import injectReducer from './../../../utils/injectReducer';

// ACTIONS
import {
  loadFilterLocations,
  loadFilterSubsidiary,
  loadFilterResource,
  loadFilterSource,
} from './actions';

// SELECTORS
import {
  makeSelectLocations,
  makeSelectSubsidiary,
  makeSelectResource,
  makeSelectSource,
} from './selectors';
import {
  makeSelectSessionAuthenticated,
  makeSelectSessionChecked,
  makeSelectSessionUser,
  makeSelectSessionUserEntitlements,
} from '../../../App/selectors';

// SAGAS
import saga from './sagas';

// REDUCERS
import reducer from './reducer';

// FORM ELEMENTS
const { Item } = Form;
const { Option } = Select;

// STYLES
const StyledItem = styled(Item)`
  margin-bottom: 0px;
`;

const StyledButton = styled(Button)`
  margin-top: 28px;
`;

class PageFilter extends React.PureComponent {
  constructor(props) {
    super(props);
    const {
      defaultSubsidiaries,
      defaultResources,
      defaultSourceSystems,
      defaultLocations,
      defaultStorageSystems,
      defaultStatus,
    } = this.props;

    this.state = {
      locations: defaultLocations || [],
      subsidiaries: defaultSubsidiaries || [],
      sourceSystems: defaultSourceSystems || [],
      resources: defaultResources || [],
      storageSystems: defaultStorageSystems || [],
      status: defaultStatus || [],
    };
  }

  componentDidMount() {
    const {
      checked, authenticated, session, entitlements,
    } = this.props;
    if (checked && !authenticated) {
      this.fetchData();
    } else if (checked && authenticated) {
      const accessToken = session.get('access_token');
      this.fetchData(accessToken, entitlements);
    }
  }

  /**
   * Update default selected values based on parent props
   *
   * @param {*} nextProps
   */
  componentWillReceiveProps(nextProps) {
    const {
      defaultSubsidiaries,
      defaultResources,
      defaultSourceSystems,
      defaultLocations,
      defaultStorageSystems,
      defaultStatus,
    } = this.props;
    const newDefaults = {};
    if (nextProps.defaultSubsidiaries !== defaultSubsidiaries) {
      newDefaults.subsidiaries = nextProps.defaultSubsidiaries || [];
    }
    if (nextProps.defaultResources !== defaultResources) {
      newDefaults.resources = nextProps.defaultResources || [];
    }
    if (nextProps.defaultSourceSystems !== defaultSourceSystems) {
      newDefaults.sourceSystems = nextProps.defaultSourceSystems || [];
    }
    if (nextProps.defaultLocations !== defaultLocations) {
      newDefaults.locations = nextProps.defaultLocations || [];
    }
    if (nextProps.defaultStorageSystems !== defaultStorageSystems) {
      newDefaults.storageSystems = nextProps.defaultStorageSystems || [];
    }
    if (nextProps.defaultStatus !== defaultStatus) {
      newDefaults.status = nextProps.defaultStatus || [];
    }
    this.setState(newDefaults);
  }

  componentDidUpdate(prevProps) {
    const {
      checked, authenticated, session, entitlements,
    } = this.props;
    if (!prevProps.checked && checked && !authenticated) {
      this.fetchData();
    } else if (
      (authenticated && !prevProps.session.size && session.size) ||
      (!prevProps.authenticated && authenticated && session.size)
    ) {
      const accessToken = session.get('access_token');
      this.fetchData(accessToken, entitlements);
    }
  }

  /** Form submit action */
  onSubmit = (e) => {
    if (e) e.preventDefault();
    const {
      subsidiaries,
      sourceSystems,
      resources,
      locations,
      storageSystems,
      status,
    } = this.state;
    // build filter object from form inputs
    const filters = {};
    if (locations.length) {
      filters.locations = locations;
    }
    if (subsidiaries.length) {
      filters.subsidiaries = subsidiaries;
    }
    if (sourceSystems.length) {
      filters.sourceSystems = sourceSystems;
    }
    if (resources.length) {
      filters.resources = resources;
    }
    if (storageSystems.length) {
      filters.storageSystems = storageSystems;
    }
    if (status.length) {
      filters.status = status;
    }
    this.props.onSubmit(filters);
  };

  /** update locations state from form */
  updateLocations = (e) => {
    this.setState({ locations: [...e] }, () => {
      // update filters
      const {
        checked, authenticated, session, entitlements,
      } = this.props;
      if (checked && !authenticated) {
        // this.props.onLoadLocations(this.state);
        this.props.onLoadSubsidiary(this.state);
        this.props.onLoadResource(this.state);
        this.props.onLoadSource(this.state);
      } else if (authenticated && session.size) {
        const accessToken = session.get('access_token');
        // this.props.onLoadLocations(this.state, accessToken, entitlements);
        this.props.onLoadSubsidiary(this.state, accessToken, entitlements);
        this.props.onLoadResource(this.state, accessToken, entitlements);
        this.props.onLoadSource(this.state, accessToken, entitlements);
      }

      this.onSubmit();
    });
  };

  /** update subsidiaries state from form */
  updateSubsidiaries = (e) => {
    this.setState({ subsidiaries: [...e] }, () => {
      // update filters
      const {
        checked, authenticated, session, entitlements,
      } = this.props;
      if (checked && !authenticated) {
        this.props.onLoadLocations(this.state);
        // this.props.onLoadSubsidiary(this.state);
        this.props.onLoadResource(this.state);
        this.props.onLoadSource(this.state);
      } else if (authenticated && session.size) {
        const accessToken = session.get('access_token');
        this.props.onLoadLocations(this.state, accessToken, entitlements);
        // this.props.onLoadSubsidiary(this.state, accessToken, entitlements);
        this.props.onLoadResource(this.state, accessToken, entitlements);
        this.props.onLoadSource(this.state, accessToken, entitlements);
      }

      this.onSubmit();
    });
  };

  /** update source systems state from form */
  updateSourceSystems = (e) => {
    this.setState({ sourceSystems: [...e] }, () => {
      // update filters
      const {
        checked, authenticated, session, entitlements,
      } = this.props;
      if (checked && !authenticated) {
        this.props.onLoadLocations(this.state);
        this.props.onLoadSubsidiary(this.state);
        this.props.onLoadResource(this.state);
        // this.props.onLoadSource(this.state);
      } else if (authenticated && session.size) {
        const accessToken = session.get('access_token');
        this.props.onLoadLocations(this.state, accessToken, entitlements);
        this.props.onLoadSubsidiary(this.state, accessToken, entitlements);
        this.props.onLoadResource(this.state, accessToken, entitlements);
        // this.props.onLoadSource(this.state, accessToken, entitlements);
      }

      this.onSubmit();
    });
  };

  /** update resources state from form */
  updateResources = (e) => {
    this.setState({ resources: [...e] }, () => {
      // update filters
      const {
        checked, authenticated, session, entitlements,
      } = this.props;
      if (checked && !authenticated) {
        this.props.onLoadLocations(this.state);
        this.props.onLoadSubsidiary(this.state);
        // this.props.onLoadResource(this.state);
        this.props.onLoadSource(this.state);
      } else if (authenticated && session.size) {
        const accessToken = session.get('access_token');
        this.props.onLoadLocations(this.state, accessToken, entitlements);
        this.props.onLoadSubsidiary(this.state, accessToken, entitlements);
        // this.props.onLoadResource(this.state, accessToken, entitlements);
        this.props.onLoadSource(this.state, accessToken, entitlements);
      }

      this.onSubmit();
    });
  };

  /** update storage systems state from form */
  updateStorageSystems = (e) => {
    this.setState({ storageSystems: [...e] }, () => {
      // update filters
      const {
        checked, authenticated, session, entitlements,
      } = this.props;
      if (checked && !authenticated) {
        this.props.onLoadLocations(this.state);
        this.props.onLoadSubsidiary(this.state);
        this.props.onLoadResource(this.state);
        this.props.onLoadSource(this.state);
      } else if (authenticated && session.size) {
        const accessToken = session.get('access_token');
        this.props.onLoadLocations(this.state, accessToken, entitlements);
        this.props.onLoadSubsidiary(this.state, accessToken, entitlements);
        this.props.onLoadResource(this.state, accessToken, entitlements);
        this.props.onLoadSource(this.state, accessToken, entitlements);
      }

      this.onSubmit();
    });
  };

  /** update storage systems state from form */
  updateStatus = (e) => {
    this.setState({ status: [...e] }, () => {
      this.onSubmit();
    });
  };

  fetchData = (token, entitlements) => {
    this.props.onLoadLocations(this.state, token, entitlements);
    this.props.onLoadSubsidiary(this.state, token, entitlements);
    this.props.onLoadResource(this.state, token, entitlements);
    this.props.onLoadSource(this.state, token, entitlements);
  };

  render() {
    const {
      defaultSubsidiaries,
      subsidiaries,
      defaultResources,
      resources,
      defaultSourceSystems,
      sourceSystems,
      defaultLocations,
      locations,
      defaultStorageSystems,
      storageSystems,
      storageSystemsTitle,
      locationsData,
      subsidiaryData,
      resourceData,
      sourceData,
      status,
      statusTitle,
      defaultStatus,
    } = this.props;

    // count the number of inputs enabled, used to calculate column widths
    const numCols =
      !!subsidiaries + !!resources + !!sourceSystems + !!locations + !!storageSystems + !!status;
    const colOpts = {
      sm: 24,
      lg: numCols === 1 ? 21 : Math.floor(22 / numCols),
    };
    return (
      <Card>
        <Form layout="vertical" onSubmit={this.onSubmit}>
          <Row gutter={16}>
            {locations && (
              <Col {...colOpts}>
                <StyledItem label="Location">
                  <Select
                    mode="multiple"
                    placeholder="Select Location"
                    onChange={this.updateLocations}
                    defaultValue={defaultLocations}
                    getPopupContainer={() => document.getElementById('layout-content')}
                    filterOption={(inputValue, option) => {
                      if (
                        option.props.children
                          .toLocaleLowerCase()
                          .indexOf(inputValue.toLocaleLowerCase()) > -1
                      ) {
                        return true;
                      }
                      return false;
                    }}
                    value={this.state.locations}
                  >
                    {locationsData.get('_items') &&
                      locationsData.get('_items').map(loc => (
                        <Option key={`${loc.get('_id')}`} value={`${loc.get('_id')}`}>
                          {`(${loc.get('_id')}) ${loc.get('name')}`}
                        </Option>
                      ))}
                  </Select>
                </StyledItem>
              </Col>
            )}
            {subsidiaries && (
              <Col {...colOpts}>
                <StyledItem label="Subsidiary">
                  <Select
                    allowClear
                    mode="multiple"
                    placeholder="Select Subsidiary"
                    onChange={this.updateSubsidiaries}
                    defaultValue={defaultSubsidiaries}
                    getPopupContainer={() => document.getElementById('layout-content')}
                    value={this.state.subsidiaries}
                  >
                    {subsidiaryData.map(sub => (
                      <Option val={sub.get('_id')} key={sub.get('_id')}>
                        {sub.get('_id')} - {sub.get('name')}
                      </Option>
                    ))}
                  </Select>
                </StyledItem>
              </Col>
            )}
            {resources && (
              <Col {...colOpts}>
                <StyledItem label="Resource">
                  <Select
                    allowClear
                    mode="multiple"
                    placeholder="Select Resource"
                    onChange={this.updateResources}
                    defaultValue={defaultResources}
                    getPopupContainer={() => document.getElementById('layout-content')}
                    value={this.state.resources}
                  >
                    {resourceData.map(res => (
                      <Option val={res.get('_id')} key={res.get('_id')}>
                        {res.get('_id')}
                      </Option>
                    ))}
                  </Select>
                </StyledItem>
              </Col>
            )}
            {sourceSystems && (
              <Col {...colOpts}>
                <StyledItem label="Source System">
                  <Select
                    allowClear
                    mode="multiple"
                    placeholder="Select Source System"
                    onChange={this.updateSourceSystems}
                    defaultValue={defaultSourceSystems}
                    getPopupContainer={() => document.getElementById('layout-content')}
                    value={this.state.sourceSystems}
                  >
                    {sourceData.map(source => (
                      <Option val={source.get('_id')} key={source.get('_id')}>
                        {source.get('_id')}
                      </Option>
                    ))}
                  </Select>
                </StyledItem>
              </Col>
            )}
            {storageSystems && (
              <Col {...colOpts}>
                <StyledItem label={storageSystemsTitle || 'Storage System'}>
                  <Select
                    allowClear
                    mode="multiple"
                    placeholder="Select Storage System"
                    onChange={this.updateStorageSystems}
                    defaultValue={defaultStorageSystems}
                    getPopupContainer={() => document.getElementById('layout-content')}
                    value={this.state.storageSystems}
                  >
                    {storageSystems.map(store => (
                      <Option val={store.id} key={store.name}>
                        {store.name}
                      </Option>
                    ))}
                  </Select>
                </StyledItem>
              </Col>
            )}
            {status && (
              <Col {...colOpts}>
                <StyledItem label={statusTitle || 'Status'}>
                  <Select
                    allowClear
                    mode="multiple"
                    placeholder="Select Validation Status"
                    onChange={this.updateStatus}
                    defaultValue={defaultStatus}
                    getPopupContainer={() => document.getElementById('layout-content')}
                    value={this.state.status}
                  >
                    {status.map(stat => (
                      <Option val={stat.id} key={stat.id}>
                        {stat.name}
                      </Option>
                    ))}
                  </Select>
                </StyledItem>
              </Col>
            )}
            <Col span={3}>
              <StyledButton type="primary" htmlType="submit">
                Filter
              </StyledButton>
            </Col>
          </Row>
        </Form>
      </Card>
    );
  }
}

PageFilter.propTypes = {
  onSubmit: PropTypes.func,
  onLoadSource: PropTypes.func,
  onLoadResource: PropTypes.func,
  onLoadLocations: PropTypes.func,
  onLoadSubsidiary: PropTypes.func,
  subsidiaries: PropTypes.arrayOf(PropTypes.object),
  resources: PropTypes.arrayOf(PropTypes.object),
  sourceSystems: PropTypes.arrayOf(PropTypes.object),
  locations: PropTypes.arrayOf(PropTypes.object),
  storageSystems: PropTypes.arrayOf(PropTypes.object),
  defaultSubsidiaries: PropTypes.arrayOf(PropTypes.string),
  defaultResources: PropTypes.arrayOf(PropTypes.string),
  defaultSourceSystems: PropTypes.arrayOf(PropTypes.string),
  defaultLocations: PropTypes.arrayOf(PropTypes.string),
  defaultStorageSystems: PropTypes.arrayOf(PropTypes.string),
  defaultStatus: PropTypes.arrayOf(PropTypes.string),
  status: PropTypes.arrayOf(PropTypes.object),
  storageSystemsTitle: PropTypes.string,
  locationsData: ImmutablePropTypes.map, // eslint-disable-line
  subsidiaryData: ImmutablePropTypes.list, // eslint-disable-line
  resourceData: ImmutablePropTypes.list, // eslint-disable-line
  sourceData: ImmutablePropTypes.list, // eslint-disable-line
  session: ImmutablePropTypes.map, // eslint-disable-line
  checked: PropTypes.bool,
  authenticated: PropTypes.bool,
  entitlements: PropTypes.object, // eslint-disable-line
  statusTitle: PropTypes.string,
};

PageFilter.defaultProps = {
  onSubmit: () => console.warn('no on click prop'), // eslint-disable-line
  onLoadSource: () => console.warn('no onLoadSource prop'), // eslint-disable-line
  onLoadResource: () => console.warn('no onLoadResource prop'), // eslint-disable-line
  onLoadLocations: () => console.warn('no onLoadLocations prop'), // eslint-disable-line
  onLoadSubsidiary: () => console.warn('no onLoadSubsidiary prop'), // eslint-disable-line
  subsidiaries: undefined,
  resources: undefined,
  sourceSystems: undefined,
  locations: undefined,
  storageSystems: undefined,
  defaultSubsidiaries: undefined,
  defaultResources: undefined,
  defaultSourceSystems: undefined,
  defaultLocations: undefined,
  defaultStorageSystems: undefined,
  status: undefined,
  defaultStatus: undefined,
  storageSystemsTitle: undefined,
  locationsData: fromJS({}),
  subsidiaryData: fromJS([]),
  resourceData: fromJS([]),
  sourceData: fromJS([]),
  checked: false,
  authenticated: false,
  statusTitle: undefined,
};

export function mapDispatchToProps(dispatch) {
  return {
    onLoadLocations: (filters, token, entitlements) => {
      dispatch(loadFilterLocations(filters, token, entitlements));
    },
    onLoadSubsidiary: (filters, token, entitlements) => {
      dispatch(loadFilterSubsidiary(filters, token, entitlements));
    },
    onLoadResource: (filters, token, entitlements) => {
      dispatch(loadFilterResource(filters, token, entitlements));
    },
    onLoadSource: (filters, token, entitlements) => {
      dispatch(loadFilterSource(filters, token, entitlements));
    },
  };
}

const mapStateToProps = createStructuredSelector({
  authenticated: makeSelectSessionAuthenticated(),
  checked: makeSelectSessionChecked(),
  session: makeSelectSessionUser(),
  entitlements: makeSelectSessionUserEntitlements(),
  locationsData: makeSelectLocations(),
  subsidiaryData: makeSelectSubsidiary(),
  resourceData: makeSelectResource(),
  sourceData: makeSelectSource(),
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);
const withReducer = injectReducer({ key: 'filters', reducer });
const withSaga = injectSaga({ key: 'filters', saga });

export default compose(
  withReducer,
  withSaga,
  withConnect,
)(PageFilter);
