/**
 * ValidationPage Sagas
 *
 * Gets the validation from the api
 */

import { call, put, takeLatest } from 'redux-saga/effects';
import { LOAD_VALIDATION } from './constants';
import { validationLoaded, validationLoadingError } from './actions';
import { createInFilterString } from './../../../utils/query';
import request from './../../../utils/request';

/**
 * validation data request/response handler
 */
export function* getValidationData({
  date, filters, token, entitlements,
}) {
  const { subsidiaries, status } = filters;
  let { locations, resources, sourceSystems } = filters;
  let requestURL = '';
  const query = { $and: [] };
  const dateQuery = {
    date: { $gte: date, $lte: date },
  };
  if (entitlements && !entitlements.Locations.includes('*')) {
    if (!locations) {
      locations = entitlements.Locations;
    } else {
      locations = locations.filter(el => entitlements.Locations.indexOf(el) > -1);
    }
  }
  if (entitlements && !entitlements.Resources.includes('*')) {
    if (!resources) {
      resources = entitlements.Resources;
    } else {
      resources = resources.filter(el => entitlements.Resources.indexOf(el) > -1);
    }
  }
  if (entitlements && !entitlements.SourceSystems.includes('*')) {
    if (!sourceSystems) {
      sourceSystems = entitlements.SourceSystems;
    } else {
      sourceSystems = sourceSystems.filter(el => entitlements.SourceSystems.indexOf(el) > -1);
    }
  }
  if (subsidiaries || resources || sourceSystems || locations || status) {
    if (locations) {
      query.$and.push({ 'location._id': createInFilterString(locations) });
    }
    if (subsidiaries) {
      query.$and.push({ 'location.subsidiary': createInFilterString(subsidiaries) });
    }
    if (resources) {
      query.$and.push({ resource: createInFilterString(resources) });
    }
    if (sourceSystems) {
      query.$and.push({ source_system: createInFilterString(sourceSystems) });
    }
    if (status) {
      if (status.includes('passed')) {
        query.$and.push({ passed: true });
      }
      if (status.includes('failed')) {
        query.$and.push({ passed: false });
      }
      if (status.includes('needs_validation')) {
        query.$and.push({ needs_validation: true });
      }
    }
    query.$and.push(dateQuery);
    requestURL = `${process.env.REACT_APP_EDC_HOST ||
      ''}/dnc/edc/v1/ALL/validation?max_results=1000&projection={"issues":0}&where=${JSON.stringify(
      query,
    )}&sort=-date`;
  } else {
    requestURL = `${process.env.REACT_APP_EDC_HOST ||
      ''}/dnc/edc/v1/ALL/validation?max_results=1000&projection={"issues":0}&where=${JSON.stringify(
      dateQuery,
    )}&sort=-date`;
  }

  const options = {
    method: 'GET',
  };
  if (token) {
    options.headers = {
      Authorization: `Bearer ${token}`, // eslint-disable-line
    };
  } else {
    requestURL = `${requestURL}&apikey=${process.env.REACT_APP_API_KEY}`;
  }
  try {
    const valData = yield call(request, requestURL, options);
    yield put(validationLoaded(valData));
  } catch (err) {
    yield put(validationLoadingError(err));
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export default function* validationData() {
  // get the latest api call
  yield takeLatest(LOAD_VALIDATION, getValidationData);
}
