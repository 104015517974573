/*
 * ReportRedirectPage
 *
 * Report Redirect Page - Generates and provides a temporary presigned link to the end user.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { Alert, Card, Spin } from 'antd';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { compose } from 'redux';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { parse } from 'qs';

// STYLES
import { Section, StyledContent } from './../../../global-styles';

// SELECTORS
import {
  makeSelectSessionAuthenticated,
  makeSelectSessionUser,
} from './../../../App/selectors';

class ReportRedirectPage extends React.PureComponent {
  constructor(props, context) {
    super(props, context);
    const { location } = this.props;

    this.query = parse(location.search.substr(1)); // get params from url

    this.state = {
      presigned_url: null,
      request_finished: false,
    };
  }

  componentDidMount() {
    const { accessToken } = this.props;

    // Extract location id from the object key (default is 'ALL')
    const locationId = (
      this.query.report.match(/\S+\/\S+\/(\S+)\/\d{4}-\d{2}-\d{2}/) || ['', 'ALL']
    )[1];

    // eslint-disable-next-line
    const requestString = `${process.env.REACT_APP_EDC_HOST || ''}/dnc/edc/v1/${locationId}/reports/?get_presigned_url=${this.query.report}`;
    const jsonOptions = {
      method: 'GET',
      headers: {
         Authorization: `Bearer ${accessToken}`, // eslint-disable-line
        Accept: 'application/json',
        'Cache-Control': 'no-cache',
      },
    };

    fetch(requestString, jsonOptions)
      .then(response => response.json())
      .then((data) => { this.setState({ request_finished: true, ...data }); });
  }

  /** render component */
  render() {
    const {
      authenticated, loading, meta,
    } = this.props;

    if (this.state.presigned_url) {
      window.location = this.state.presigned_url;
    }

    return (
      <StyledContent>
        <Helmet>
          <title>Enterprise Data Explorer - Report Redirect</title>
          <meta name="description" content="Enterprise Data Explorer Dashboard" />
        </Helmet>
        <Section>
          <Card title="" loading={!!loading}>
            {!authenticated && (
              <Alert
                message="You must login to retrieve the report."
                description=""
                type="error"
              />
            )}

            {/*
                If user authenticated, contains valid report name
                but retrieval of presigned_url is not finished
            */}
            {authenticated &&
            this.query.report &&
            !this.state.presigned_url &&
            !this.state.request_finished && (
            <div>Finding report ... <Spin size="small" /></div>
            )}

            {/*
                If user authenticated, contains valid report name,
                and successfully retrieves presigned_url
            */}
            {authenticated &&
            this.state.presigned_url &&
            this.query.report && (
              <div>Redirecting to report ...</div>
            )}

            {/* If user authenticated, but did retrieve a valid presigned_url */}
            {authenticated && this.state.request_finished && !this.state.presigned_url && (
              <Alert
                message="Could not find report. Please verify the download link."
                description=""
                type="error"
              />
            )}
          </Card>
        </Section>
      </StyledContent>
    );
  }
}

ReportRedirectPage.propTypes = {
  loading: PropTypes.bool,
  authenticated: PropTypes.bool,
  session: ImmutablePropTypes.map, // eslint-disable-line
  match: PropTypes.object, // eslint-disable-line
  accessToken: PropTypes.string, // eslint-disable-line
  meta: ImmutablePropTypes.map, // eslint-disable-line
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }),
};

ReportRedirectPage.defaultProps = {
  loading: false,
  authenticated: false,
  location: {},
};

const mapStateToProps = createStructuredSelector({
  session: makeSelectSessionUser(),
  authenticated: makeSelectSessionAuthenticated(),
});

const withConnect = connect(
  mapStateToProps,
);

export default compose(withConnect)(ReportRedirectPage);
