export const captureException = (e) => {
  if (
    typeof window !== 'undefined' &&
    typeof window.Raven !== 'undefined' &&
    !(window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1')
  ) {
    window.Raven.captureException(e);
  }
};

export const captureWarningMessage = (message) => {
  if (
    typeof window !== 'undefined' &&
    typeof window.Raven !== 'undefined' &&
    !(window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1')
  ) {
    window.Raven.captureMessage(message || 'Warning captured', {
      level: 'warning', // one of 'info', 'warning', or 'error'
    });
  }
};

export const setSentryUser = (email) => {
  if (
    typeof window !== 'undefined' &&
    typeof window.Raven !== 'undefined' &&
    !(window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1')
  ) {
    window.Raven.setUserContext({
      email,
    });
  }
};
