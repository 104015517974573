/**
 * LoginPage
 *
 * Handle user id and secret and password
 */
import React from 'react';
import { Icon } from 'antd';
import styled from 'styled-components';

// STYLES
export const StyledErrorHeader = styled.h1`
  color: #434e59;
  font-size: 54px;
  font-weight: 600;
  line-height: 54px;
  margin-bottom: 12px;
`;

export const StyledErrorDescription = styled.div`
  color: rgba(0, 0, 0, 0.45);
  font-size: 20px;
  line-height: 28px;
  margin-bottom: 16px;
`;

export const StyledErrorIcon = styled(Icon)`
  color: #434e59;
  font-size: 71px;
  line-height: 71px;
  margin-bottom: 24px;
`;

export const StyledErrorContent = styled.div`
  min-height: calc(98vh - 175px);
  text-align: center;
`;

const LegacyPage = () => (
  <StyledErrorContent>
    <StyledErrorIcon type="warning" />
    <StyledErrorHeader>Please Update Your Browser</StyledErrorHeader>
    <StyledErrorDescription>
      Delaware North Enterprise Data Explorer requires the latest browsers.
    </StyledErrorDescription>
  </StyledErrorContent>
);

export default LegacyPage;
