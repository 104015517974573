/*
 * ReportsPortalAudit Constants
 *
 * Follow this format:
 * export const YOUR_ACTION_CONSTANT = 'ede/LocationDetail/YOUR_ACTION_CONSTANT';
 */

export const LOAD_REPORTS = 'ede/ReportsPortalAudit/LOAD_REPORTS';
export const LOAD_REPORTS_SUCCESS = 'ede/ReportsPortalAudit/LOAD_REPORTS_SUCCESS';
export const LOAD_REPORTS_ERROR = 'ede/ReportsPortalAudit/LOAD_REPORTS_ERROR';
