/*
 * Location Actions
 *
 */
import {
  LOAD_LOCATIONS_FILTER,
  LOAD_LOCATIONS_FILTER_SUCCESS,
  LOAD_SUBSIDIARY_FILTER,
  LOAD_SUBSIDIARY_FILTER_SUCCESS,
  LOAD_RESOURCE_FILTER,
  LOAD_RESOURCE_FILTER_SUCCESS,
  LOAD_SOURCE_FILTER,
  LOAD_SOURCE_FILTER_SUCCESS,
} from './constants';

/**
 * Load the activity, this action starts the request saga
 *
 * @return {object} An action object with a type of LOAD_ACTIVITY
 */
export function loadFilterLocations(filters, token, entitlements) {
  return {
    type: LOAD_LOCATIONS_FILTER,
    filters: filters || {},
    token,
    entitlements,
  };
}

/**
 * Dispatched when the locations are loaded by the request saga
 *
 * @param  {object} locations The locaitons data
 *
 * @return {object}      An action object with a type of LOAD_LOACTIONS_SUCCESS passing the repos
 */
export function locationsFilterLoaded(locations) {
  return {
    type: LOAD_LOCATIONS_FILTER_SUCCESS,
    locations,
  };
}

/**
 * Load the activity, this action starts the request saga
 *
 * @return {object} An action object with a type of LOAD_ACTIVITY
 */
export function loadFilterSubsidiary(filters, token, entitlements) {
  return {
    type: LOAD_SUBSIDIARY_FILTER,
    filters: filters || {},
    token,
    entitlements,
  };
}

/**
 * Dispatched when the locations are loaded by the request saga
 *
 * @param  {object} locations The locaitons data
 *
 * @return {object}      An action object with a type of LOAD_LOACTIONS_SUCCESS passing the repos
 */
export function subsidiaryFilterLoaded(data) {
  return {
    type: LOAD_SUBSIDIARY_FILTER_SUCCESS,
    data,
  };
}

/**
 * Load the activity, this action starts the request saga
 *
 * @return {object} An action object with a type of LOAD_ACTIVITY
 */
export function loadFilterResource(filters, token, entitlements) {
  return {
    type: LOAD_RESOURCE_FILTER,
    filters: filters || {},
    token,
    entitlements,
  };
}

/**
 * Dispatched when the locations are loaded by the request saga
 *
 * @param  {object} locations The locaitons data
 *
 * @return {object}      An action object with a type of LOAD_LOACTIONS_SUCCESS passing the repos
 */
export function resourceFilterLoaded(data) {
  return {
    type: LOAD_RESOURCE_FILTER_SUCCESS,
    data,
  };
}

/**
 * Load the activity, this action starts the request saga
 *
 * @return {object} An action object with a type of LOAD_ACTIVITY
 */
export function loadFilterSource(filters, token, entitlements) {
  return {
    type: LOAD_SOURCE_FILTER,
    filters: filters || {},
    token,
    entitlements,
  };
}

/**
 * Dispatched when the locations are loaded by the request saga
 *
 * @param  {object} locations The locaitons data
 *
 * @return {object}      An action object with a type of LOAD_LOACTIONS_SUCCESS passing the repos
 */
export function sourceFilterLoaded(data) {
  return {
    type: LOAD_SOURCE_FILTER_SUCCESS,
    data,
  };
}
