import styled, { injectGlobal } from 'styled-components';
import { Row, Table } from 'antd';

/* eslint no-unused-expressions: 0 */
const globals = injectGlobal`
  html,
  body {
    height: 100%;
    width: 100%;
    margin: 0;
    overflow-y: hidden;
    padding: 0;
  }
  body {
    /* font-family: 'Montserrat', 'Helvetica Neue', Helvetica, Arial, sans-serif; */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  #app {
    background-color: #fafafa;
    min-height: 100%;
    min-width: 100%;
  }
  #root {
    height: 100%;
  }
  p,
  label {
    line-height: 1.5em;
  },
  h1, h2, h3 {
    color: #4a4a4a;
  }

  .ant-layout.ant-layout-has-sider > .ant-layout, .ant-layout.ant-layout-has-sider > .ant-layout-content {
    overflow-x: hidden;
  }
`;

export const Section = styled(Row)`
  margin-bottom: 24px;
  @media (max-width: 768px) {
    margin-bottom: 12px;
  }
`;

export const StyledTable = styled(Table)`
  .ant-table-small {
    border: none;
  }
  .ant-table-pagination.ant-pagination {
    margin-bottom: 0px;
  }
`;

export const StyledContent = styled.div`
  min-height: calc(100vh - 170px);
`;

export default globals;
