/*
 * Validation Constants
 *
 * Follow this format:
 * export const YOUR_ACTION_CONSTANT = 'ede/Validation/YOUR_ACTION_CONSTANT';
 */

export const LOAD_VALIDATION = 'ede/ValidationQuery/LOAD_VALIDATION';
export const LOAD_VALIDATION_SUCCESS = 'ede/ValidationQuery/LOAD_VALIDATION_SUCCESS';
export const LOAD_VALIDATION_ERROR = 'ede/ValidationQuery/LOAD_VALIDATION_ERROR';
