/**
 * ValidationPage Reducer
 *
 * The reducer takes handles actions and manipulates the validation data.
 *
 */

import { fromJS } from 'immutable';

import { LOAD_VALIDATION, LOAD_VALIDATION_SUCCESS, LOAD_VALIDATION_ERROR } from './constants';

// The initial state of the App
export const initialState = fromJS({
  loading: false,
  error: false,
  validationData: {},
});

function activityReducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_VALIDATION:
      return state
        .set('loading', true)
        .set('error', false)
        .set('validationData', fromJS({}));
    case LOAD_VALIDATION_SUCCESS:
      return state.set('validationData', fromJS(action.validation)).set('loading', false);
    case LOAD_VALIDATION_ERROR:
      return state.set('error', action.error).set('loading', false);
    default:
      return state;
  }
}

export default activityReducer;
