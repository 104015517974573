/**
 * ActivityPage Sagas
 *
 * Gets the activity data from the api
 */

import { call, put, takeLatest } from 'redux-saga/effects';
import {
  LOAD_LOCATIONS_FILTER,
  LOAD_SUBSIDIARY_FILTER,
  LOAD_RESOURCE_FILTER,
  LOAD_SOURCE_FILTER,
} from './constants';
import {
  locationsFilterLoaded,
  subsidiaryFilterLoaded,
  resourceFilterLoaded,
  sourceFilterLoaded,
} from './actions';
import { createInFilterString } from './../../../utils/query';
import request from './../../../utils/request';

/**
 * location data request/response handler
 */
export function* getLocationData({ filters, token, entitlements }) {
  let where = null;
  if (entitlements && !entitlements.Locations.includes('*')) {
    if (!where) where = {};
    where._id = createInFilterString(entitlements.Locations);
  }
  if (filters && filters.subsidiaries.length) {
    if (!where) where = {};
    where.subsidiary = createInFilterString(filters.subsidiaries);
  }
  if (filters && filters.resources.length) {
    if (!where) where = {};
    if (!where.resources) where.resources = {};
    where.resources.$elemMatch = { name: createInFilterString(filters.resources) };
  }
  if (filters && filters.sourceSystems.length) {
    if (!where) where = {};
    if (!where.resources) where.resources = {};
    where.resources.$elemMatch = {
      ...where.resources.$elemMatch,
      'source_systems.name': createInFilterString(filters.sourceSystems),
    };
  }
  let requestURL = `${process.env.REACT_APP_EDC_HOST ||
    ''}/dnc/edc/v1/ALL/locations?max_results=1000&${
    where ? `where=${JSON.stringify(where)}` : ''
  }&sort=name`;
  const options = {
    method: 'GET',
  };
  if (token) {
    options.headers = {
      Authorization: `Bearer ${token}`, // eslint-disable-line
    };
  } else {
    requestURL = `${requestURL}&apikey=${process.env.REACT_APP_API_KEY}`;
  }
  try {
    const data = yield call(request, requestURL, options);
    yield put(locationsFilterLoaded(data));
  } catch (err) {
    // yield put(locationsLoadingError(err));
  }
}

/**
 * TODO: FIX
 * meta data request/response handler
 */
export function* getSubsidiaryData({ filters, token, entitlements }) {
  let where = null;
  if (entitlements && !entitlements.Locations.includes('*')) {
    if (!where) where = {};
    where._id = createInFilterString(entitlements.Locations);
  }
  if (filters && filters.locations.length) {
    if (!where) where = {};
    where._id = createInFilterString(filters.locations);
  }
  if (filters && filters.resources.length) {
    if (!where) where = {};
    if (!where.resources) where.resources = {};
    where.resources.$elemMatch = { name: createInFilterString(filters.resources) };
  }
  if (filters && filters.sourceSystems.length) {
    if (!where) where = {};
    if (!where.resources) where.resources = {};
    where.resources.$elemMatch = {
      ...where.resources.$elemMatch,
      'source_systems.name': createInFilterString(filters.sourceSystems),
    };
  }
  let subsidiariesURL = `${process.env.REACT_APP_EDC_HOST || ''}/dnc/edc/v1/ALL/meta/subsidiaries${
    where ? `?where=${JSON.stringify(where)}` : ''
  }`;
  const options = {
    method: 'GET',
  };
  if (token) {
    options.headers = {
      Authorization: `Bearer ${token}`, // eslint-disable-line
    };
  } else {
    subsidiariesURL = `${subsidiariesURL}${where ? '&' : '?'}apikey=${
      process.env.REACT_APP_API_KEY
    }`;
  }
  try {
    const data = yield call(request, subsidiariesURL, options);
    yield put(subsidiaryFilterLoaded(data));
  } catch (err) {
    // yield put(metaLoadingError(err));
  }
}

/**
 * meta data request/response handler
 */
export function* getResourcesData({ filters, token, entitlements }) {
  let where = null;
  if (entitlements && !entitlements.Locations.includes('*')) {
    if (!where) where = {};
    where._id = createInFilterString(entitlements.Locations);
  }
  if (filters && filters.locations.length) {
    if (!where) where = {};
    where._id = createInFilterString(filters.locations);
  }
  if (filters && filters.subsidiaries.length) {
    if (!where) where = {};
    where.subsidiary = createInFilterString(filters.subsidiaries);
  }
  if (filters && filters.sourceSystems.length) {
    if (!where) where = {};
    if (!where['resources.source_systems']) where['resources.source_systems'] = {};
    where['resources.source_systems'].$elemMatch = {
      name: createInFilterString(filters.sourceSystems),
    };
  }
  let resourcesURL = `${process.env.REACT_APP_EDC_HOST || ''}/dnc/edc/v1/ALL/meta/resources${
    where ? `?where=${JSON.stringify(where)}` : ''
  }`;
  const options = {
    method: 'GET',
  };
  if (token) {
    options.headers = {
      Authorization: `Bearer ${token}`, // eslint-disable-line
    };
  } else {
    resourcesURL = `${resourcesURL}${where ? '&' : '?'}apikey=${process.env.REACT_APP_API_KEY}`;
  }
  try {
    const data = yield call(request, resourcesURL, options);
    yield put(resourceFilterLoaded(data));
  } catch (err) {
    // yield put(metaLoadingError(err));
  }
}

/**
 * meta data request/response handler
 */
export function* getSourceData({ filters, token, entitlements }) {
  let where;
  if (entitlements && !entitlements.SourceSystems.includes('*')) {
    if (!where) where = {};
    where.source_system = createInFilterString(entitlements.SourceSystems);
  }
  if (entitlements && !entitlements.Locations.includes('*')) {
    if (!where) where = {};
    where._id = createInFilterString(entitlements.Locations);
  }
  if (filters && filters.locations.length) {
    if (!where) where = {};
    where._id = createInFilterString(filters.locations);
  }
  if (filters && filters.subsidiaries.length) {
    if (!where) where = {};
    where.subsidiary = createInFilterString(filters.subsidiaries);
  }
  if (filters && filters.resources.length) {
    if (!where) where = {};
    if (!where['resources.name']) where['resources.name'] = {};
    where['resources.name'] = createInFilterString(filters.resources);
  }

  let sourceSystemsURL = `${process.env.REACT_APP_EDC_HOST ||
    ''}/dnc/edc/v1/ALL/meta/source_systems${where ? `?where=${JSON.stringify(where)}` : ''}`;
  const options = {
    method: 'GET',
  };
  if (token) {
    options.headers = {
      Authorization: `Bearer ${token}`, // eslint-disable-line
    };
  } else {
    sourceSystemsURL = `${sourceSystemsURL}${where ? '&' : '?'}apikey=${
      process.env.REACT_APP_API_KEY
    }`;
  }
  try {
    const data = yield call(request, sourceSystemsURL, options);
    yield put(sourceFilterLoaded(data));
  } catch (err) {
    // yield put(metaLoadingError(err));
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export default function* filterData() {
  // get the latest api call
  yield takeLatest(LOAD_LOCATIONS_FILTER, getLocationData);
  yield takeLatest(LOAD_SUBSIDIARY_FILTER, getSubsidiaryData);
  yield takeLatest(LOAD_RESOURCE_FILTER, getResourcesData);
  yield takeLatest(LOAD_SOURCE_FILTER, getSourceData);
}
